import axios from "axios";
import React, { useState, createContext, useEffect, useContext } from "react";

export const ApplyContext = createContext({});

export const DataContext = () => useContext(ApplyContext);

function ApplyProvider({ children }) {
  let url = process.env.REACT_APP_URL;
  const [success, setSuccess] = useState(false);
  const [assDate, setAssDate] = useState({});
  const [courses, setCourses] = useState([]);
  const [err, setErr] = useState({
    err1: false,
    err2: false,
    err3: false,
  });
  const title = {
    0: "Contact Details",
    1: "Career and Skill",
    2: "Course Option",
  };
  const [page, setPage] = useState(0);
  const form_data = {
    email: "",
    first_name: "",
    last_name: "",
    other_name: "",
    phone: "",
    birth_date: "",
    gender: "",
    country: "",
    state: "",
    address: "",
    occupation: "",
    course: "",
    education: "",
    program_level: "",
    session: "",
    learning_mode: "",
    assessment_date: "",
    assessment_time: "",
    more_about_you: "",
    hear_about_us: "",
    ref_code: "",
  };
  const [data, setData] = useState({
    email: "",
    first_name: "",
    last_name: "",
    other_name: "",
    phone: "",
    birth_date: "",
    gender: "",
    country: "",
    state: "",
    address: "",
    occupation: "",
    course_manager_id: "",
    education: "",
    program_level: "",
    session: "",
    learning_mode: "",
    assessment_date: "",
    assessment_time: "",
    more_about_you: "",
    hear_about_us: "",
    ref_code: "",
  });
  const myData =
    data.course_manager_id === "5vc6wcus2kzefvaxn" ||
    data.course_manager_id === "5vc6wc5rvl3xaj4l3" ||
    data.course_manager_id === "5vc6wwb8plumpt5qh" ||
    data.course_manager_id === "5vc6w1bem3e5blre" ||
    data.course_manager_id === "5vc6w1efsfl8a225ej"
      ? Object.keys(data).filter((key) => key !== "assessment_date" && key !== "assessment_time")
      : Object.keys(data).filter((key) => key);

  const newData = {};
  myData.forEach((key) => {
    newData[key] = data[key];
  });

  const handleChange = (e) => {
    const { type, name } = e.target;
    let value = type === "radio" && e.target.checked ? e.target.value : e.target.value;
    if (name === "learning_mode" && e.target.value.includes("Hybrid")) value = "hybrid";
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const { other_name, ref_code, ...requiredData } = newData;

  const saveData = [...Object.values(requiredData)].every(Boolean) && page === Object.keys(title).length - 1;

  const savePage1 = Object.keys(data)
    .slice(0, 9)
    .filter((key) => key !== "other_name")
    .map((key) => data[key])
    .every(Boolean);

  const savePage2 = Object.keys(data)
    .slice(9, 14)
    .map((key) => data[key])
    .every(Boolean);

  const disablePrev = page === 0;

  const hidePrev = page === 0 && "hide-btn";
  const hideNext = page === Object.keys(title).length - 1 && "hide-btn";
  const hideSubmit = page !== Object.keys(title).length - 1 && "hide-btn";

  useEffect(() => {
    const getAssDate = async () => {
      try {
        const resp = await axios.get(`${url}/learnmode-assessment-date`);
        const data = await resp.data;
        setAssDate(data[0]);
      } catch (err) {
        throw Error(err);
      }
    };
    const getCourses = async () => {
      try {
        const resp = await axios.get(`${url}/courses/manager/active`);
        if (resp.statusText === "OK") {
          const data = resp.data;
          setCourses((prev) => (prev = data));
        }
      } catch (error) {
        console.log("An Error has occurred");
      }
    };

    getAssDate();
    getCourses();
  }, [url]);

  const vals = {
    title,
    page,
    setPage,
    data,
    saveData,
    handleChange,
    disablePrev,
    hidePrev,
    hideNext,
    hideSubmit,
    savePage1,
    savePage2,
    err,
    setErr,
    requiredData,
    success,
    setSuccess,
    form_data,
    setData,
    assDate,
    courses,
  };

  return <ApplyContext.Provider value={vals}>{children}</ApplyContext.Provider>;
}

export default ApplyProvider;
